
/**
 * @description
 * 신규 홈 화면 > 재능
 */

import { SCROLL_HOME } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { HOME_TYPE } from '@/utils/amplitudeEvent/amplitudeParams.js';

import EducationInfoSection from '@/components/new-home/common/education-info/EducationInfoSection.vue';
import OngoingCourses from '@/components/new-home/common/ongoing-courses/OngoingCourses.vue';
import UnAuthBottom from '@/components/new-home/layout/UnAuthBottom.vue';
import FixedCuration from '@/components/new-home/talent/fixed-curation/FixedCuration.vue';
import MostPurchased from '@/components/new-home/talent/most-purchased/MostPurchased.vue';
import ReviewCuration from '@/components/new-home/talent/review/ReviewCuration.vue';
import Teacher from '@/components/new-home/talent/teacher/Teacher.vue';
import TrendingCategory from '@/components/new-home/talent/trending-category/TrendingCategory.vue';
import Trending from '@/components/new-home/talent/trending/Trending.vue';
import NewClassSection from '@/components/new-home/common/new-class-section/NewClassSection.vue';

export default {
  name: 'Talent',
  components: {
    Trending,
    MostPurchased,
    Teacher,
    EducationInfoSection,
    ReviewCuration,
    TrendingCategory,
    FixedCuration,
    OngoingCourses,
    UnAuthBottom,
    NewClassSection,
  },
  scrollToTop: true,
  data() {
    return {
      HOME_TYPE,
      // raf
      rafTimeout: false,
      // 스크롤 체크하려는 퍼센트 리스트
      scrollDepth: [25, 50, 75, 100],
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('scroll', this.scrollEventRAF);
    });
  },
  destroyed() {
    this.removeScrollEvent();
    document.removeEventListener('scroll', this.scrollEventRAF);
  },
  methods: {
    removeScrollEvent() {
      document.removeEventListener('scroll', this.scrollEventRAF);
    },
    // requestAnimationFrame 화면 프레임 단위로 이벤트를 호출하게 도와줍니다.
    scrollEventRAF() {
      if (!this.rafTimeout) {
        window.requestAnimationFrame(() => {
          this.checkScrollEvent();
          this.rafTimeout = false;
        });
        this.rafTimeout = true;
      }
    },
    // 스크롤 이벤트 트래킹
    checkScrollEvent() {
      const scroll = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const viewport = scrollHeight - clientHeight;
      const percentage = Math.floor((scroll / viewport) * 100);

      // 계산한 퍼센트와 비교하려는 퍼센트 리스트를 비교해줍니다.
      this.scrollDepth.forEach(p => {
        if (percentage >= p) {
          // 넘는 퍼센트가 있으면 해당 값을 리스트에서 제거해주고 이벤트를 찍습니다.
          this.scrollDepth = this.scrollDepth.filter(v => v !== p);
          this.logScrollEvent(p);
        }
      });

      // 더 체크할 퍼센트 리스트가 없으면 스크롤 이벤트를 정리해줍니다.
      if (this.scrollDepth.length === 0) this.removeScrollEvent();
    },
    logScrollEvent(percentage) {
      this.logUserCustomEvent(SCROLL_HOME, {
        scroll_depth: `${percentage}%`,
        home_type: HOME_TYPE.TALENT,
      });
    },
  },
};
